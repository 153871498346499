if (!window.number_format) {
    window.number_format = function (number, decimals, dec_point, separator) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof separator === 'undefined') ? ',' : separator,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + (Math.round(n * k) / k)
                    .toFixed(prec);
            };
        // Фиксим баг в IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
            .split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '')
            .length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1)
                .join('0');
        }
        return s.join(dec);
    }
}


$(document).ready(function () {
    var header = $('.head'),
        scrollPrev = 0;

    $(window).scroll(function() {
        var scrolled = $(window).scrollTop();

        if ( scrolled > 100 && scrolled > scrollPrev ) {
            header.addClass('out');
        } else {
            header.removeClass('out');
        }
        scrollPrev = scrolled;
    });

    $(document).on("click", ".site-up", function (){
        $('html, body').animate({
            scrollTop: 0
        }, {
            duration: 200,
        });
    });

    $(window).on("scroll", function (){
        if ($(this).scrollTop() > 110) {
            $("body").addClass("scrolled")
        } else {
            $("body").removeClass("scrolled")
        }
    })

    $(".slider .items").owlCarousel({
        items : 1,
        nav: true,
        loop: true
    })

    $(".main-catalog-new .items").owlCarousel({

        autoplay : true,
        responsive: {
            0 : {
                items : 2
            },
            760 : {
                items : 3
            },
            992 : {
                items : 4
            },
            1200 : {
                autoplay : false,
                items : 5
            },
        },
        nav: true,
        dots: false
    })

    $(".main-catalog-hits .items").owlCarousel({

        autoplay : true,
        responsive: {
            0 : {
                items : 2
            },
            760 : {
                items : 3
            },
            992 : {
                items : 4
            },
            1200 : {
                autoplay : false,
                items : 5
            },
        },
        nav: true,
        dots: false
    })

    $('.search-input').autoComplete({
        minChars: 3,
        source: function(term, response){
            $.getJSON('/catalog/json', { text: term }, function(data){ response(data); });
        }
    });
});