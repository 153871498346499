$(document).ready(function (){

    var owl = {
        big_pics : $(".big-pic-wrapper .img-slider"),
        thumb_pics : $(".thumb-pics-wrapper .img-slider")
    };
    owl.big_pics_owl = owl.big_pics.owlCarousel({
        items : 1,
        dots : false
    });
    owl.thumb_pics_owl = owl.thumb_pics.owlCarousel({
        items : 4,
        dots : false,
        responsive : {
            0 : {
                items : 3
            },
            410 : {
                items : 4
            },
            992 : {
                items : 5
            },
            1200 : {
                items : 6
            }
        }
    });

    $(".pics-next").click(function(){
        owl.big_pics.data("owl.carousel").next();
        owl.thumb_pics.data("owl.carousel").next();
    });
    $(".pics-prev").click(function(){
        owl.big_pics.data("owl.carousel").prev();
        owl.thumb_pics.data("owl.carousel").prev();
    });
    owl.big_pics.on('changed.owl.carousel', function(event) {
        owl.thumb_pics.find(".td").removeClass("active").eq(event.item.index).addClass("active");
    });
    owl.thumb_pics.find(".td").eq(0).addClass("active");
    owl.thumb_pics.find(".td").each(function(i){
        $(this).hover(function(){
            owl.big_pics.data("owl.carousel").to(i, null, true);
        });
    });


})