(function($){
    $.fn.offersButtons = function(options){

        var o = $.extend({
            properties : {},
            stores : {},
            offers : {},
            colors: {},
            init : function () {}
        }, options);

        return this.each(function(){

            var offersButtons = $(this);
            var buttons = {};
            var selected = {};
            var offer = null;


            function Button(property_id, key, isColor) {
                this.property_id = property_id;
                this.key = key;
                var self = this;
                this.offers = [];
                this.variants = {};
                this.dom = $("#variant-" + property_id + "-" + key);
                this.disabled = false;
                this.active = false;

                if (isColor && o.colors[o.properties[this.property_id].variants[key].toLowerCase()]) {
                    var colorPic = '/f/' + o.colors[o.properties[this.property_id].variants[key].toLowerCase()].pic;
                    this.dom = $('<i class="property-variant property-variant-color" style="background-image: url(\''+colorPic+'\')" title="'+ o.properties[this.property_id].variants[key]+'">&nbsp;</i>')
                    this.dom.css("background-image", colorPic)
                } else {
                    this.dom = $('<i class="property-variant">' + o.properties[this.property_id].variants[key] + '</i>')
                }

                this.enable = function() {
                    self.dom.removeClass("disable");
                    self.disabled = false;
                };

                this.disable = function() {
                    self.dom.addClass("disable");
                    self.disabled = true;
                    self.deactivate();
                };

                this.deactivate = function () {
                    if (self.isActive()) {
                        self.active = false;
                        self.dom.removeClass("active");
                        selected[self.property_id] = null;
                    }
                };

                this.activate = function() {
                    $.each(buttons[self.property_id], function (key, button) {
                        button.deactivate();
                    });
                    self.enable();
                    self.dom.removeClass("disable").addClass("active");
                    self.active = true;
                    selected[self.property_id] = self;
                };

                this.isActive = function () {
                    return self.active;
                };

                this.isDisabled = function () {
                    return self.disabled;
                };

                function init() {

                    $.each(o.offers, function (offer_id, offer) {
                        // если значение присутствует в этом предложении

                        if (offer.properties[self.property_id] &&
                            offer.properties[self.property_id].value == o.properties[self.property_id]["variants"][self.key]) {
                            // добавляем в связанные предложения
                            self.offers.push(offer_id);

                            $.each(offer.properties, function (offer_property_id, property) {
                                if (offer_property_id != self.property_id) {
                                    if (!self.variants[offer_property_id]) {
                                        self.variants[offer_property_id] = {};
                                    }
                                    var vKey = getVariantKey(offer_property_id, property.value);
                                    self.variants[offer_property_id][vKey] = o.properties[offer_property_id]["variants"][vKey];
                                }
                            })
                        }
                    });

                    $(self.dom).click(function () {
                        selectVariant(self);
                    });

                }

                init();

            }

            function getVariantKey(property_id, value) {
                for (var key in o.properties[property_id].variants) {
                    if (o.properties[property_id].variants[key] == value) {
                        return key;
                    }
                }
            }

            function selectVariant(button) {
                if (button.isDisabled()) return false;
                selected[button.property_id] = button.key;
                button.activate();
                $.each(buttons, function (property_id, propertyButtons) {
                    $.each(propertyButtons, function (key, rowButton) {

                        if (property_id == button.property_id) {
                            rowButton.enable();
                        } else {
                            if (button.variants[property_id][key] !== null && button.variants[property_id][key] !== undefined) {
                                rowButton.enable();
                            } else {
                                rowButton.disable();
                            }
                        }
                    });
                    if (!selected[property_id]) {
                        activeFirst(property_id);
                    }
                });
                setOffer();
                offersButtons.trigger("selectOffer", offer);
            }

            function activeFirst(property_id) {
                for (var key in buttons[property_id]) {
                    if ( !buttons[property_id][key].isDisabled() ) {
                        buttons[property_id][key].activate();
                        return;
                    }
                }
            }

            function setOffer() {
                var arr = [];
                for (var property_id in selected){
                    arr.push(selected[property_id].offers);
                }
                var offer_id = arrIntersect(arr);
                offer = o.offers[offer_id];

            }


            function arrIntersect(arr) {
                var tmp_arr = [];
                for(var arr_index=0; arr_index<arr.length; arr_index++){
                    if(arr_index==0) tmp_arr = arr[arr_index];
                    else{
                        var A = tmp_arr;
                        var B = arr[arr_index];
                        var m = A.length, n = B.length, c = 0, C = [];
                        for (var i = 0; i < m; i++) {
                            var j = 0, k = 0;
                            while (B[j] !== A[ i ] && j < n) j++;
                            while (C[k] !== A[ i ] && k < c) k++;
                            if (j != n && k == c) C[c++] = A[ i ];
                        }
                        tmp_arr = C;
                    }
                }
                return tmp_arr[0];
            }


            function init() {

                if (o.offers == null || o.offers == undefined) {
                   // disableShop();
                } else if (Object.keys(o.offers).length === 1) {
                    for (var i in o.offers) {
                        offer = o.offers[i];
                        break;
                    }
                } else {
                    if (o.properties) {
                        var fistButton;
                        offersButtons.append('<div class="h">Укажите необходимые параметры:</div>');
                        var select_buttons = $('<div class="select-offers">');
                        for (var property_id in o.properties) {
                            buttons[property_id] = {};
                            var offers_property = $('<div class="offers-property clearfix">');
                            offers_property.append('<div class="o-label">' + o.properties[property_id].name + ':</div>');
                            var property_variants = $('<div class="variants">');
                            var isColor = o.properties[property_id].name.toLowerCase() === 'цвет' && o.colors;
                            for (var key in o.properties[property_id].variants) {

                                buttons[property_id][key] = new Button(property_id, key, isColor);
                                property_variants.append(buttons[property_id][key].dom);
                                if (!fistButton) {
                                    fistButton = buttons[property_id][key];
                                }
                            }
                            offers_property.append(property_variants);
                            select_buttons.append(offers_property);
                        }

                        offersButtons.append(select_buttons);

                        //  включаем первый вариант
                        fistButton.dom.trigger("click");
                    }

                }
                o.init(o, buttons, offer);
            }
            init();
        });
    };
})(jQuery);