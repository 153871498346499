window.cart = (function($){
    var options = {
        count : "#cartCount",
        sum : "#cartSum",
        url : null,
        mask : '#<i>Р</i>'
    };

    var init = function (countSelector, sumSelector, url, mask) {
        options.count = countSelector;
        options.sum = sumSelector;
        options.url = url;
        options.mask = mask;
    };

    var add = function (o) {
        $.ajax({
            url : options.url + "/add",
            data : {id : o.id, count : o.count},
            cache : false,
            dataType : "json",
            success : function (data) {
                if (data.count) {
                    changeWidget(data);
                }
                if (o.success) o.success(data);
            },
            error : function (e) {
                alert("Не удалось добавить товар, попробуйте ещё раз");
                if (o.error) o.error(e);
            },
            complete : function (e) {
                if (o.complete) o.complete(e);
            }
        })
    };

    var changeWidget = function (data) {
        $(options.count).html(data.count).show();
        $(options.sum).html(options.mask.replace('#', data.sum));
    };

    var formatPrice = function (price, mask) {
        return mask.replace('#', number_format(price, 0, '.', ' '));
    };

    var remove = function (o) {
        $.ajax({
            url : options.url + "/remove",
            data : {id : o.id},
            cache : false,
            dataType : "json",
            success : function (data) {
                if (o.success) o.success(data);
            },
            error : function (e) {
                alert("Не удалось удалить товар, попробуйте ещё раз");
                if (o.error) o.error(e);
            },
            complete : function (e) {
                if (o.complete) o.complete(e);
            }
        });
    };

    var changeCount = function (o) {
        $.ajax({
            url : options.url + "/change-count",
            data : {id : o.id, count : o.count},
            cache : false,
            dataType : "json",
            success : function (data) {
                if (o.success) o.success(data);
            },
            error : function (e) {
                alert("Не удалось изменить количество, попробуйте ещё раз");
                if (o.error) o.error(e);
            },
            complete : function (e) {
                if (o.complete) o.complete(e);
            }
        });
    };

    return {
        init : init,
        add : add,
        formatPrice : formatPrice,
        remove : remove,
        changeCount : changeCount,
        changeWidget : changeWidget
    }

})(jQuery);