function Aside(selector){
    this.el = document.querySelector(selector);
    this.opened = false;
    this.buttons = [];
    this.first = null;
    this.second = null;
    this.prev = null;
    this.items = [];


    this.init();
}

Aside.prototype.init = function () {
    this.buttons = document.querySelectorAll(".aside-toggle");
    var self = this;

    this.buttons.forEach(function (button){
        button.addEventListener("click", function (e){
            self.toggle(button);
            e.stopPropagation();
        })
    })
    window.addEventListener("click", function (e){
        if (self.opened && !self.el.contains(e.target)) {
            self.close();
        }
    })
    this.close();
    this.initBlocks();
    this.initMenu();
}
Aside.prototype.initBlocks = function (){
    this.first = this.el.querySelector(".aside-first");
    this.second = this.el.querySelector(".aside-second");
    this.prev = this.el.querySelector(".aside-prev");
}
Aside.prototype.toggle = function (button) {
    if (this.opened) {
        this.close();
    } else {
        this.open();
    }
}
Aside.prototype.open = function () {
    var self = this;
    this.opened = true;
    this.el.style.display = "block";
    this.buttons.forEach(function (button){
        button.classList.add("opened");
    })
    document.body.classList.add("fixed")
}

Aside.prototype.close = function () {
    this.opened = false;
    this.el.style.display = "none";
    this.buttons.forEach(function (button){
        button.classList.remove("opened");
    })
    document.body.classList.remove("fixed")
}

Aside.prototype.initMenu = function () {
    var self = this;
    var menuAll = this.first.querySelectorAll(".aside-menu");
    var firstItem, n = 0;
    menuAll.forEach(function (ul){
        var liAll = ul.children;
//        for (var li of liAll) {
        for (var i=0; i < liAll.length; i++) {
            var li = liAll[i];
            if(li.tagName.toUpperCase() !== "LI") continue;
            var a = li.querySelector("a");
            var subUl = li.querySelector("ul");
            if (subUl) {
                li.classList.add("sub");
                var sub = self.createSub(subUl, a)
                var item = {
                    li : li,
                    sub : sub,
                    active : false
                }
                self.second.appendChild(sub);
                self.items.push(item);

                let onClick = function (item){
                    return function (e) {
                        if (!item.active) {
                            self.openItem(item);
                            e.preventDefault();
                        }
                    }
                }
                if (n === 0) {
                    firstItem = item;
                }

                a.addEventListener("click", onClick(item));
            }
            n++;
        }
    });

    this.prev.addEventListener("click", function (e){
        self.closeAll();
    });
    if (firstItem && window.outerWidth > 992){
        this.openItem(firstItem);
    }
}

Aside.prototype.createSub = function (subUl, a){
    var sub = document.createElement("div");
    var h = document.createElement("a");
    h.href = a.href;
    h.innerHTML = a.innerHTML;
    h.classList.add("sub-h");

    sub.classList.add("sub-menu");
    sub.appendChild(h);
    sub.appendChild(subUl);

    return sub;
}

Aside.prototype.openItem = function (item) {
    this.closeAll();
    item.li.classList.add("active");
    item.sub.classList.add("active");
    item.active = true;
    this.second.style.display = "block";

    this.slideSecond();
}

Aside.prototype.slideSecond = function (){

    var self = this;
    var left = 100;

    function moveRight(){
        left -= 10;
        self.second.style.left = left + "%"
        if (left > 0) {
            setTimeout(moveRight, 5);
        } else {
            if (window.innerWidth < 992) {
                window.scrollTo(0, 0);
            }
        }
    }
    this.second.style.left = "100%"
    moveRight();

}

Aside.prototype.closeAll = function (){
    this.items.forEach(function (item){
        item.li.classList.remove("active");
        item.sub.classList.remove("active");
        item.active = false;
    })
    this.second.style.display = "none";
}


window.addEventListener("DOMContentLoaded", function (){
    const aside = new Aside("#MainMenu");
});
